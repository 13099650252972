<template>
  <v-card>
    <v-img
      :src="src"
      :lazy-src="require('@/assets/imgs/services/blur.png')"
    />
    <v-card-title class="d-flex justify-center">
      {{ name }}
    </v-card-title>
    <v-card-actions class="d-flex justify-center">
      <base-btn
        :href="url"
        target="blank"
      >
        {{ btnText }}
      </base-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
  export default {
    name: 'ServiceCard',
    props: {
      src: String,
      name: String,
      url: String,
      btnText: String,
    },
  }
</script>
